export default ({
    gap = 24,
    margin = 12,
    horPosition = 'left',
    offset = 36,
} = {}) => ({
    flyout: false,
    initFlyout: false,
    initFlyoutBCR: null,
    initPointerBCR: null,
    targetBCR: null,
    gap,
    margin,
    offset,
    onResize () {
        this.targetBCR = this.$refs.target.getBoundingClientRect();
    },
    async init () {
        this.initFlyout = true;
        await new Promise(resolve => setTimeout(resolve, 100));
        this.targetBCR = this.$refs.target.getBoundingClientRect();
        this.initFlyoutBCR = this.$refs.flyout.getBoundingClientRect();
        this.initPointerBCR = this.$refs.pointer.getBoundingClientRect();
        this.initFlyout = false;
    },
    toggle () {
        this.flyout = !this.flyout;
    },
    get flyoutStyle () {
        const opacity = this.flyout ? 1 : 0;
        const zIndex = this.flyout ? 20 : -1;
        let top = 0;
        let left = 0;
        if (this.flyout) {
            const { gap, margin, offset } = this;
            top = this.targetBCR.y;
            top -= this.initFlyoutBCR.height;
            top -= gap;
            top = Math.max(top,margin);
            top = top > margin ? top : this.targetBCR.y + this.targetBCR.height + gap;
            top = Math.min(top,window.innerHeight - this.initFlyoutBCR.height);
            top += 'px';
            left = this.targetBCR.x;
            left += this.targetBCR.width / 2;
            if (horPosition === 'center') {
                left -= this.initFlyoutBCR.width / 2;
            }  else if (horPosition === 'right') {
                left -= this.initFlyoutBCR.width;
                left += offset;
            } else {
                left -= offset;
            }
            left = Math.max(left,margin);
            left = Math.min(left,window.innerWidth - this.initFlyoutBCR.width - margin);
            left += 'px';
        }
        return { top, left, opacity, zIndex };
    },
    get pointerStyle () {
        let top = 0;
        let left = 0;
        let transform = null;
        if (this.flyout) {
            const { gap, margin } = this;
            top = this.targetBCR.y;
            top -= gap;
            top = Math.max(top,margin);
            transform = top > margin + this.initFlyoutBCR.height ? 'rotate(45deg)' : 'rotate(-135deg)';
            top = top > margin + this.initFlyoutBCR.height ? top : this.targetBCR.y + this.targetBCR.height + gap;
            top = Math.min(top,window.innerHeight);
            top += 'px';
            left = this.targetBCR.x;
            left += this.targetBCR.width / 2;
            left = Math.max(left,this.initPointerBCR.width + margin);
            left = Math.min(left,window.innerWidth - this.initPointerBCR.width - margin);
            left += 'px';
        }
        return { top, left, transform };
    },
});
