export default function() {
    return {
        today: null,
        init () {
            this.today = new Date();
            this.today = new Date(this.today.toDateString());
        },
        mouseover (date) {
            if (this.isDisabled(date)) { return; }
            this.getDateValue(date, true);
        },
        isDisabled (date) {
            const d = new Date(this.year, this.month, date);

            if (d < this.today) { return true; }

            const isXDaysInFuture = (x, y, z) => x > new Date(y).setDate(new Date(y).getDate() + z);

            return isXDaysInFuture(d, this.today, 365);
        },
        isWeekend (date) {
            const d = new Date(this.year, this.month, date);
            return d.getDay() > 4 && d.getDay() < 7;
        },
    }
}