import Alpine from 'alpinejs'
import datePicker from './alpine/datePicker'
import focus from '@alpinejs/focus'
import ownerplusDatePickerCell from './alpine/ownerplusDatePickerCell'
import tooltipSimple from './alpine/tooltipSimple'
import '~/owners.css'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.data('datePicker', datePicker)
Alpine.data('ownerplusDatePickerCell', ownerplusDatePickerCell)
Alpine.data('tooltipSimple', tooltipSimple)
Alpine.start()
